var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "grid", class: ["animated fadeIn"] },
    [
      _c(
        "el-row",
        [
          _c(
            "el-form",
            {
              attrs: {
                inline: "",
                "label-suffix": ":",
                "label-position": "left",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "流程名称" } },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "请输入流程名称",
                      size: "mini",
                      clearable: "",
                    },
                    model: {
                      value: _vm.form.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "流程标识" } },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "请输入流程标识",
                      size: "mini",
                      clearable: "",
                    },
                    model: {
                      value: _vm.form.key,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "key", $$v)
                      },
                      expression: "form.key",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        size: "mini",
                        icon: "el-icon-search",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("search", _vm.form)
                        },
                      },
                    },
                    [_vm._v("搜索")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini", icon: "el-icon-delete" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("reset")
                        },
                      },
                    },
                    [_vm._v("清空")]
                  ),
                  _c("el-button", {
                    attrs: { size: "mini", circle: "", icon: "el-icon-s-data" },
                    on: {
                      click: function ($event) {
                        return _vm.$emit("mode", "list")
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.data.length > 0
        ? _c(
            "el-row",
            { attrs: { gutter: 20 } },
            _vm._l(_vm.data, function (item, index) {
              return _c(
                "el-col",
                {
                  key: index,
                  staticClass: "grid-col",
                  attrs: { xs: 24, sm: 12, md: 6, lg: 6, xl: 6 },
                },
                [
                  _c(
                    "el-card",
                    {
                      attrs: {
                        shadow: "hover",
                        "body-style": { padding: "10px 20px" },
                      },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.$emit("route", item)
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "grid-item",
                          attrs: { title: item.name },
                        },
                        [
                          _c("img", {
                            staticClass: "icon",
                            attrs: {
                              src:
                                item.icon ||
                                require("../../../assets/img/icon-default.png"),
                            },
                          }),
                          _c("div", { staticClass: "info" }, [
                            _c("div", { staticClass: "name txt-cut" }, [
                              _vm._v(
                                _vm._s(item.name) + "：v" + _vm._s(item.version)
                              ),
                            ]),
                            _c("div", { staticClass: "key txt-cut" }, [
                              _vm._v(_vm._s(item.key)),
                            ]),
                          ]),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              )
            }),
            1
          )
        : [
            _vm.avueVersion("2.9.4")
              ? _c("avue-empty", {
                  staticStyle: { "margin-top": "20%" },
                  attrs: { desc: "没有可发起的流程" },
                })
              : _c("el-empty", {
                  staticStyle: { "margin-top": "20%" },
                  attrs: { description: "没有可发起的流程" },
                }),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }